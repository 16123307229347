import React, { useCallback, useState } from 'react';

import classy from '@core/utils/classy';

import classes from './style.module.scss';

interface Props {
  className?: string;
  dismissible?: boolean;
  icon?: string;
  isFixed?: boolean;
  kind?: 'alert' | 'primary' | 'secondary' | 'warn';
  onDismiss?: () => void;
  size?: 'md' | 'sm' | 'xs';
  style?: React.CSSProperties;
}

const Banner: React.FC<Props> = ({
  children,
  className,
  dismissible = false,
  icon = 'icon-alert-circle',
  isFixed = true,
  kind = 'secondary',
  onDismiss,
  size = 'sm',
  style,
  ...attr
}) => {
  const [dismissed, setDismissed] = useState(false);

  const dismiss = useCallback(() => {
    if (!dismissible) return;
    onDismiss?.();
    setDismissed(true);
  }, [dismissible, onDismiss]);

  const onKeyPress = useCallback(
    event => {
      if (!dismissible) return;
      if (event.key === 'Enter') dismiss();
    },
    [dismiss, dismissible],
  );

  if (dismissed) return null;

  return (
    <div
      {...attr}
      className={classy(
        className,
        classes.Banner,
        classes[`Banner_${kind}`],
        classes[`Banner_${size}`],
        isFixed && classes.Banner_fixed,
        dismissible && classes.Banner_dismissible,
      )}
      style={style}
    >
      {!!icon && <i className={icon} />}

      {children}

      {!!dismissible && (
        <i
          className={classy(classes['Banner-Dismiss'], 'icon-x-circle')}
          onClick={dismiss}
          onKeyPress={onKeyPress}
          role="button"
          tabIndex={0}
        />
      )}
    </div>
  );
};

export default Banner;
